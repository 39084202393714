exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-dotacje-js": () => import("./../../../src/pages/pl/dotacje.js" /* webpackChunkName: "component---src-pages-pl-dotacje-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-potwierdzenie-js": () => import("./../../../src/pages/pl/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-pl-kontakt-potwierdzenie-js" */),
  "component---src-pages-pl-polityka-cookie-js": () => import("./../../../src/pages/pl/polityka-cookie.js" /* webpackChunkName: "component---src-pages-pl-polityka-cookie-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-pl-projekty-js": () => import("./../../../src/pages/pl/projekty.js" /* webpackChunkName: "component---src-pages-pl-projekty-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

